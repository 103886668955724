import { useEffect } from 'react'
import { toast as showToast } from 'sonner'
import { type ToastMessage } from 'remix-toast'

export function useToast(toast?: ToastMessage | null) {
	useEffect(() => {
		if (toast) {
			setTimeout(() => {
				// Used the toast type from remix-toast instead from our wrapper toast.server.ts
				showToast[toast.type](toast.description, {
					description: toast.message,
				})
			}, 0)
		}
	}, [toast])
}
